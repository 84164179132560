import lilly from './lilly.png';
import pfizer from './pfizer.png';
import sanofi from './sanofi.png';
import leo from './leopharma.jpg';
import amgen from './amgen.png';

const sponsors = [
  {
    name: 'Lilly',
    img: lilly,
  },
  {
    name: 'Sanofi',
    img: sanofi,
  },
  {
    name: 'Amgen',
    img: amgen,
  },
  {
    name: 'Pfizer',
    img: pfizer,
  },
  {
    name: 'Leo Pharma',
    img: leo,
  },
];

export default sponsors;
