import React, { useState, useEffect, useRef } from 'react';
import { HelperIcon, TrendsIcon } from '../../assets/icons';
import TrendsChart from './TrendsChart';
import TrendsFilters from './TrendsFilters';
import TrendsSummary from './TrendsSummary';
import TrendsViewPicker from './TrendsViewPicker';
import Tutorial from '../shared/Tutorial';
import FlarePoscoradChart from './FlarePoscoradChart';
import AtopicDermatitisControlSummary from './ADCTSummary';
import moment from 'moment-timezone';

const TrendsTutorial = {
  titles: ['Learn About Your Trends'],
  subtitles: [``],
  texts: [
    'Visit the Learn tab to see snapshots of each week you tracked. Changes in your symptoms can reveal patterns in trigger exposure and treatment effects.',
  ],
  urlsGif: [''],
};

const PoscoradTutorial = {
  titles: ['What is the PO-SCORAD?'],
  subtitles: [``],
  texts: [
    'PO-SCORAD is a measure of eczema severity and is generated when you complete the skin, itch, and sleep trackers in the same day. Use your score at your next appointment to help you and your doctor discuss your symptoms and evaluate if your treatment plan is working.',
  ],
  urlsGif: [''],
};

const Trends = ({ selectedDate, scrollToADCT, ...props }) => {
  const [activeTutorialId, setActiveTutorialId] = useState(null);
  const [isTrendsSummaryLoaded, setIsTrendsSummaryLoaded] = useState(false);
  const [isTrendsChartLoaded, setIsTrendsChartLoaded] = useState(false);
  const [isTrendsADCTLoaded, setIsTrendsADCTLoaded] = useState(false);
  const [showADCT, setShowADCT] = useState(true);
  const [chartPeriod, setChartPeriod] = useState('week');
  const trendsSummaryRef = useRef(null);
  const trendsADCTRef = useRef(null);

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  useEffect(() => {
    if (
      isTrendsSummaryLoaded &&
      isTrendsChartLoaded &&
      isTrendsADCTLoaded &&
      trendsSummaryRef.current &&
      trendsADCTRef.current
    ) {
      if (selectedDate)
        trendsSummaryRef.current.scrollIntoView({ behavior: 'smooth' });

      if (scrollToADCT)
        trendsADCTRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    selectedDate,
    scrollToADCT,
    isTrendsSummaryLoaded,
    isTrendsChartLoaded,
    isTrendsADCTLoaded,
  ]);

  useEffect(() => {
    const readDefaultDate = () => {
      const dateFromUrl = new URLSearchParams(window.location.search).get(
        'date',
      );
      if (dateFromUrl) {
        setChartPeriod('month');
      }
    };
    readDefaultDate();
  }, []);

  const handleTrendsSummaryLoad = () => {
    setIsTrendsSummaryLoaded(true);
  };

  const handleTrendsChartLoad = () => {
    setIsTrendsChartLoaded(true);
  };

  const handleADCTLoad = hasData => {
    setShowADCT(hasData);
    setIsTrendsADCTLoaded(hasData);
  };

  return (
    <div className="trends">
      <header className="trends-header">
        <div className="sidebar-title trends-sidebar-header">
          <TrendsIcon />
          <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
            Trends
          </h1>
          <Tutorial
            titles={TrendsTutorial.titles}
            texts={TrendsTutorial.texts}
            subtitles={TrendsTutorial.subtitles}
            urlsGif={TrendsTutorial.urlsGif}
            onClickNext={activeTutorialUpdate.bind(this)}>
            <div className="tooltip-trigger">
              <span className="icon-instructions">
                <HelperIcon />
              </span>
            </div>
          </Tutorial>
        </div>
        <div className="trends-header-content">
          <TrendsViewPicker
            selectedDate={selectedDate}
            onChangePeriod={setChartPeriod}
          />
        </div>
      </header>
      <div className="trends-content">
        <aside className="trends-sidebar"></aside>
        <div className="trends-data">
          <div className="trends-title-container">
            <h4>PO-SCORAD & Flare Days</h4>
            <Tutorial
              titles={PoscoradTutorial.titles}
              texts={PoscoradTutorial.texts}
              subtitles={PoscoradTutorial.subtitles}
              urlsGif={PoscoradTutorial.urlsGif}
              onClickNext={activeTutorialUpdate.bind(this)}>
              <div className="tooltip-trigger">
                <span className="icon-instructions">
                  <HelperIcon />
                </span>
              </div>
            </Tutorial>
          </div>
          <div className="trends-data-card">
            <FlarePoscoradChart userid={props.me.id} period={chartPeriod} />
          </div>
        </div>
      </div>
      <div className="trends-separator" />
      <div className="trends-content">
        <aside className="trends-sidebar">
          <TrendsFilters />
        </aside>
        <div className="trends-data">
          <div className="trends-title-container">
            <h4>Trackers</h4>
          </div>
          <div className="trends-data-card">
            <div className="trendschart">
              <TrendsChart onLoad={handleTrendsChartLoad} />
            </div>
          </div>
        </div>
      </div>
      <div className="trends-separator" />
      <div className="trends-content">
        <aside className="trends-sidebar"></aside>
        <div className="trends-data">
          <div className="trends-data-card summary" ref={trendsSummaryRef}>
            <TrendsSummary onLoad={handleTrendsSummaryLoad} />
          </div>
        </div>
      </div>
      <div ref={trendsADCTRef}>
        {showADCT && (
          <AtopicDermatitisControlSummary
            onADCTData={handleADCTLoad}
            userId={props.me.id}
          />
        )}
      </div>
    </div>
  );
};

export default Trends;
