import React, { useEffect, useState } from 'react';

// LIBS
import moment from 'moment-timezone';
import * as Unicons from '@iconscout/react-unicons';
import { Chart } from 'chart.js';
import ReactTooltip from 'react-tooltip';

// COMPONENTS
import PoScoradSeverity from '../shared/PoScoradSeverity';

// QUERIES
import { useQuery } from 'react-apollo';
import { AllUserData } from '../../libs/graphql/queries/data';
import FlareTrackerInfo from '../shared/FlareTrackerInfo';

const today = moment().startOf('day');
const oneMonthAgo = moment().startOf('day').subtract(29, 'days');

const getDotColor = value => {
  if (value === null) return '#8394b5';
  if (value < 25) return '#FF9672';
  if (value < 50) return '#E95D42';
  return '#B33D26';
};

const getSeverity = value => {
  if (value === null) return '';
  if (!value || value < 25) return 'Mild';
  if (value < 50) return 'Moderate';
  return 'Severe';
};

const FlarePoscoradChart = ({ userid, period }) => {
  const dateFromUrl = new URLSearchParams(window.location.search).get('date');
  const [monthlyData, setMonthlyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [week, setWeek] = useState(0);
  const [tooltipHtml, setTooltipHtml] = useState('');
  const [defaultDate, setDefaultDate] = useState(dateFromUrl || null);

  const { data: flarePoscoradData, refetch } = useQuery(AllUserData, {
    variables: {
      id: userid,
      startDate: oneMonthAgo.format('YYYY-MM-DD'),
      endDate: today.format('YYYY-MM-DD'),
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (flarePoscoradData?.allUserData) {
      processData(flarePoscoradData.allUserData);
    }
  }, [flarePoscoradData]);

  const processData = data => {
    const monthData = [];
    const startData = oneMonthAgo.clone();
    const endData = today.clone();

    while (startData.isSameOrBefore(endData)) {
      const dailyData = {
        id: null,
        date: startData.format('YYYY-MM-DD'),
        title: startData.format('MMM. D, YYYY'),
        dayTitle: startData.format('ddd'),
        dayNumber: startData.format('DD'),
        flare: null,
        poscorad: null,
      };
      const userData = data.find(
        item => item.tracker_date === startData.format('YYYY-MM-DD'),
      );

      if (userData) {
        const poscorad = userData?.poscoradData?.level ?? null;
        const flare = userData.flareData?.flare_day ?? null;
        dailyData.id = userData.id;
        dailyData.poscorad = poscorad;
        dailyData.flare = flare !== null ? flare : null;
      }

      monthData.push(dailyData);
      startData.add(1, 'days');
    }

    setMonthlyData(monthData);
    setWeeklyData(monthData.slice(-7));
    if (!selectedData) {
      if (defaultDate) {
        const urlData = monthData.find(item => item.date === defaultDate);
        if (urlData) {
          setDefaultDate(null);
          setSelectedData(urlData);
          return;
        }
      }
      setSelectedData(monthData[monthData.length - 1]);
    }
  };

  const selectData = data => {
    setSelectedData(data);
  };

  const changeWeek = index => {
    if (period === 'month') {
      return;
    }
    if (index === -4 || index === 1) {
      return;
    }
    const monthData = [...monthlyData];
    const startIndex = monthData.length + 7 * (index - 1);
    const newWeelkyData = monthData.slice(startIndex, startIndex + 7);
    const newSelectedData = newWeelkyData[newWeelkyData.length - 1];

    setWeek(index);
    setWeeklyData(newWeelkyData);
    setSelectedData(newSelectedData);
  };

  const poscoradData = period === 'week' ? weeklyData : monthlyData;

  const drawLinesChart = poscoradData => {
    const values = poscoradData.map(item => item.poscorad);
    const colors = poscoradData.map(item => getDotColor(item.poscorad));
    const ctx = document.getElementById('PoscoradChart').getContext('2d');
    const _chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: poscoradData.map(item => item.date),
        datasets: [
          {
            label: 'PO-SCORAD',
            data: values,
            borderColor: '#8394B5',
            borderWidth: 2,
            backgroundColor: colors,
            pointRadius: period === 'week' ? 8 : 5,
            pointBorderWidth: 0,
            pointBorderColor: colors,
            fill: false,
            lineTension: 0,
          },
        ],
      },
      options: {
        spanGaps: true,
        layout: {
          padding: {
            left: period === 'week' ? 55 : 12,
            right: period === 'week' ? 55 : 12,
            top: 10,
            bottom: 10,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              display: false,
              ticks: {
                min: 0,
                max: 103,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  };

  // useEfffect when we have data
  useEffect(() => {
    if (poscoradData.length > 0) {
      drawLinesChart(poscoradData);
    }
  }, [poscoradData, period]);

  const onHoveredData = hoveredData => {
    const poscorad = hoveredData?.poscorad ?? null;
    const dotColor = getDotColor(poscorad);
    const severity = getSeverity(poscorad);
    const flare =
      hoveredData?.flare === true
        ? 'Flaring'
        : hoveredData?.flare === false
        ? 'Flare free'
        : 'Flares not tracked';

    setTooltipHtml(
      `<div class="flare-chart-tooltip-content"><div>PO-SCORAD: <span style="background-color: ${dotColor}"></span> ${
        poscorad ?? 'Not tracked'
      } ${severity}</div><div>${flare}</div></div>`,
    );
    ReactTooltip.rebuild();
  };

  const onChangeFlare = flaring => {
    setSelectedData({
      ...selectedData,
      flare: flaring,
    });
    refetch();
  };

  return (
    <div className="trends-chart-container">
      <ReactTooltip
        type="light"
        multiline={true}
        className={'flare-chart-tooltip'}
        place="bottom"
        html={true}
        clickable
        getContent={() => tooltipHtml}
      />
      <div className="navigation-container" key="nav">
        <div
          className={`navigation-button ${
            period === 'month' || week === -3 ? 'disabled' : ''
          }`}
          onClick={() => changeWeek(week - 1)}>
          <Unicons.UilAngleLeft size="24" />
        </div>
        <div className="navigation-days">
          {poscoradData.map(item => (
            <div
              key={item.date}
              className={`navigation-day ${
                item.date === selectedData?.date ? 'selected' : ''
              }`}
              onClick={() => selectData(item)}>
              {item.dayNumber}
            </div>
          ))}
        </div>
        <div
          className={`navigation-button ${
            period === 'month' || week === 0 ? 'disabled' : ''
          }`}
          onClick={() => changeWeek(week + 1)}>
          <Unicons.UilAngleRight size="24" />
        </div>
      </div>
      <div className="chart-container">
        <div className="chart-scales">
          <div className="chart-scale">103</div>
          <div className="chart-scale">50</div>
          <div className="chart-scale">0</div>
        </div>
        <div className="chart-content">
          <div className="chart-steps">
            {Array.from(Array(11).keys()).map(index => (
              <div
                className="chart-step"
                key={index}
                style={{
                  bottom: `${index * 9.7}%`,
                }}
              />
            ))}
          </div>
          <div className={`chart-lines ${period}`}>
            <canvas id="PoscoradChart"></canvas>
          </div>
          <div className="chart-bars" data-tip={''}>
            {poscoradData.map(item => (
              <div
                className={`chart-bar ${
                  item.flare === true
                    ? 'flare'
                    : item.flare === false
                    ? 'not-flare'
                    : ''
                } ${item.date === selectedData?.date ? 'active' : ''}`}
                key={item.date}
                onClick={() => selectData(item)}
                onMouseOver={() => onHoveredData(item)}></div>
            ))}
          </div>
        </div>
        <div className="chart-scales">
          <div className="chart-scale">103</div>
          <div className="chart-scale">50</div>
          <div className="chart-scale">0</div>
        </div>
      </div>
      <div className="chart-buttons">
        <div className="tracker-buttons full-width">
          <PoScoradSeverity
            dateText={''}
            skinData={1}
            itchData={1}
            sleepData={1}
            poscoradData={{ level: selectedData?.poscorad ?? null }}
            newClassName={''}
          />
          <FlareTrackerInfo
            userId={userid}
            date={selectedData?.date ?? today.format('YYYY-MM-DD')}
            flareDay={selectedData?.flare ?? null}
            onChange={onChangeFlare}
            source={'Learn'}
          />
        </div>
      </div>
    </div>
  );
};

export default FlarePoscoradChart;
