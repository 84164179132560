import React, { useState } from 'react';

// LIBS
import moment from 'moment-timezone';

// ICONS
import * as Unicons from '@iconscout/react-unicons';

// QUERIES
import { useQuery } from 'react-apollo';
import { AllUserData } from '../libs/graphql/queries/data';

const getSeverityLabel = severity => {
  if (!severity) return 'Not tracked';
  if (severity <= 25) return 'Mild';
  if (severity <= 50) return 'Moderate';
  return 'Severe';
};

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const TrackersCalendar = ({ userId, onClose, onSelectDate }) => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const today = moment();
  const oneMonthAgo = moment().subtract(1, 'month');

  const { data: flarePoscoradData } = useQuery(AllUserData, {
    variables: {
      id: userId,
      startDate: oneMonthAgo.format('YYYY-MM-DD'),
      endDate: today.format('YYYY-MM-DD'),
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const daysInMonth = currentMonth.daysInMonth();
  const firstDayOfMonth = moment(currentMonth).startOf('month');
  const lastSevenDays = Array.from({ length: 8 }, (_, i) =>
    moment().subtract(i, 'days'),
  );

  const changeMonth = direction => {
    setCurrentMonth(moment(currentMonth).add(direction, 'month'));
  };

  const isDateEnabled = date => {
    const last30Days = Array.from({ length: 30 }, (_, i) =>
      moment().subtract(i, 'days'),
    );
    return last30Days.some(d => d.isSame(date, 'day'));
  };

  const isDateEditable = date => {
    return lastSevenDays.some(d => d.isSame(date, 'day'));
  };

  const getPoscoradData = date => {
    const trackerData = flarePoscoradData?.allUserData.find(
      d => d.tracker_date === date.format('YYYY-MM-DD'),
    );
    const poscoradData = trackerData?.poscoradData;
    return poscoradData;
  };

  const renderPoscoradDot = date => {
    if (today.isBefore(date, 'day')) {
      return null;
    }

    const poscoradData = getPoscoradData(date);
    return (
      <div className={`dot ${getSeverityLabel(poscoradData?.level ?? '')}`} />
    );
  };

  const renderDays = () => {
    const blankDays = [];
    for (let i = 0; i < firstDayOfMonth.day(); i++) {
      blankDays.push(
        <div key={`disabled-${i}`} className="trackersCalendar-day disabled">
          <span>
            {moment(firstDayOfMonth)
              .subtract(firstDayOfMonth.day() - i, 'days')
              .date()}
          </span>
        </div>,
      );
    }

    const monthDays = [];
    for (let d = 1; d <= daysInMonth; d++) {
      const currentDate = moment(currentMonth).date(d);
      const isSelectable = isDateEnabled(currentDate);
      const isEditable = isDateEditable(currentDate);
      const isSelected = selectedDate.isSame(currentDate, 'day');

      monthDays.push(
        <div
          key={d}
          className={`trackersCalendar-day ${
            isEditable ? '' : isSelectable ? 'disabled-edition' : 'disabled'
          } ${isSelected ? 'selected' : ''}`}
          onClick={() => isSelectable && setSelectedDate(currentDate)}>
          <span>{`${d < 10 ? '0' : ''}${d}`}</span>
          {renderPoscoradDot(currentDate)}
        </div>,
      );
    }

    return [...blankDays, ...monthDays];
  };

  const renderResults = () => {
    const poscoradData = getPoscoradData(selectedDate);
    const level = poscoradData?.level ?? '';
    const severityLabel = getSeverityLabel(level);

    return (
      <>
        <p className="trackersCalendar-results-date">
          {moment(selectedDate).format('dddd MMM DD, YYYY')}
        </p>
        <div className="trackersCalendar-results-values">
          <span className="trackersCalendar-results-title">POSCORAD:</span>
          <span className={`trackersCalendar-results-dot ${severityLabel}`} />
          {level && (
            <span className="trackersCalendar-results-severity">{level}</span>
          )}
          <span className="trackersCalendar-results-severity">
            {severityLabel}
          </span>
        </div>
      </>
    );
  };

  const onPressSelect = () => {
    if (!onSelectDate) {
      return;
    }
    onSelectDate(selectedDate.format('YYYY-MM-DD'));
    onClose();
  };

  const onPressVisitDay = () => {
    window.location.href = `/trends?date=${selectedDate.format('YYYY-MM-DD')}`;
    onClose();
  };

  return (
    <div className="trackersCalendar">
      <div className="trackersCalendar-header">
        <h4>Select Date</h4>
        <div className="trackersCalendar-close">
          <Unicons.UilTimes size="28" onClick={onClose} />
        </div>
      </div>
      <div className="trackersCalendar-body">
        <div className="trackersCalendar-nav">
          <button
            className="trackersCalendar-nav-button"
            onClick={() => changeMonth(-1)}
            disabled={currentMonth.isSame(
              moment().subtract(1, 'month'),
              'month',
            )}>
            <Unicons.UilAngleLeft size="24" />
          </button>
          <h5 className="trackersCalendar-nav-title">
            {currentMonth.format('MMM YYYY')}
          </h5>
          <button
            className="trackersCalendar-nav-button"
            onClick={() => changeMonth(1)}
            disabled={currentMonth.isSame(moment(), 'month')}>
            <Unicons.UilAngleRight size="24" />
          </button>
        </div>
        <div className="trackersCalendar-week-header">
          {WEEK_DAYS.map(day => (
            <div key={day} className="trackersCalendar-week-header-day">
              {day}
            </div>
          ))}
        </div>

        <div className="trackersCalendar-days">{renderDays()}</div>

        <div className="trackersCalendar-results">{renderResults()}</div>

        <div className="trackersCalendar-footer">
          <button
            className="button button__secondary100-inverted button__btn-16-600 button-rectangle-skin-footer"
            onClick={onPressVisitDay}>
            Visit the day
          </button>
          {isDateEditable(selectedDate) && (
            <button
              className="button button__secondary100 button__btn-16-600 button-rectangle-skin-footer"
              onClick={onPressSelect}>
              Track
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackersCalendar;
